<template>
  <div>
    <div class="d-flex col-6" >
      <XCheckbox
          label="VoLTE Hack"
          :value="!!value.volteHack"
          class="mb-6"
          id="explorerCellularAttachParameters-volteHack"
          @input="updateFieldValue('volteHack', $event ? 1 : 0)"/>
      <XCheckbox
          label="Cell Info"
          :value="!!value.cellInfo"
          class="mb-6 ml-5"
          id="explorerCellularAttachParameters-cellInfo"
          @input="updateFieldValue('cellInfo', $event ? 1 : 0)"/>
      <primaryTextfield
          v-if="value.cellInfo"
          class="ml-2"
          type="number"
          name="cellInfoInterval"
          label="Cell Info Interval (Seconds)"
          v-model="cellInfoInterval"
          :fieldAttrInput="{ style: 'min-width:200px', min:'5', max:'3600'}"
      ></primaryTextfield>
    </div>
    <div class="d-flex  mt-n6 col-8">
      Selecting the SIM is not necessary if you have already used the ‘Insert SIM’ step. However, you still need to configure the networks and radio access technology.
    </div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value,type: simType,slot: simSlot,ratType: radioRatType, configure5G: radioConfigure5G }"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import options from '@/cfg/options.json';
import XCheckbox from '@/components/basic/XCheckbox.vue';
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";


export default {
  components: {
    primaryTextfield,
    XCheckbox,
    fieldsRows,
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  data() {
    return {
      options: options,
    };
  },
  mounted() {
    if (this.value.radio.ratType === 'any' && this.value.radio.configure5G === undefined) {
      this.updateFieldValue('ratType', 'any');
    }
    if (this.value.volteHack === undefined) {
      this.updateFieldValue('volteHack', 1);
    }
  },
  computed: {
    cellInfoInterval: {
      get() {
        return this.value.cellInfo ? this.value["cellInfoInterval"]:0;
      },
      set(newVal) {
        this.updateFieldValue("cellInfoInterval", newVal)
      },
    },
    simType() {
      let simType = '';
      if (typeof this.value !== undefined &&
          typeof this.value.sim !== undefined &&
          typeof this.value.sim.type !== undefined
      ) {
        simType = this.value.sim.type;
      }
      return simType;
    },
    simSlot() {
      let simSlot = '';
      if (typeof this.value !== undefined &&
          typeof this.value.sim !== undefined &&
          typeof this.value.sim.slot !== undefined
      ) {
        simSlot = this.value.sim.slot;
      }
      return simSlot;
    },
    radioRatType() {
      let radioRatType = '';
      if (typeof this.value !== undefined &&
          typeof this.value.radio !== undefined &&
          typeof this.value.radio.ratType !== undefined
      ) {
        radioRatType = this.value.radio.ratType;
      }
      return radioRatType;
    },
    radioConfigure5G() {
      let radioConfigure5G = '';
      if (
          typeof this.value !== undefined &&
          typeof this.value.radio !== undefined &&
          typeof this.value.radio.configure5G !== undefined
      ) {
        radioConfigure5G = this.value.radio.configure5G;
      } else if (this.value.radio.ratType === 'any') {
        radioConfigure5G = 'full';
      }
      return radioConfigure5G;
    },
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
              type: {
                type: 'addable',
                name: 'SIM Type',
                associatedOption: ['local'],
                setEmpty: false,
                ignoreEmptyAdd: true,
                default: 'local',
                colAttrs: {cols: 1},
              },
              slot: {
                type: 'addable',
                name: 'SIM Slot',
                associatedOption: [
                  [1, '1'],
                  [2, '2'],
                  [3, '3'],
                  [4, '4'],
                  [5, '5'],
                  [6, '6'],
                  [7, '7'],
                  [8, '8'],
                ],
                setEmpty: false,
                ignoreEmptyAdd: true,
                sort: false,
                colAttrs: {
                  class: 'slot',
                  style: 'max-width: 150px;',
                },
              },
              network: {
                type: 'text',
                name: 'Network',
                fieldAttrInput: {
                  //class: 'mcc-mnc number',
                  // maxlength: 6, /*,max:999999*/
                },
                colAttrs: {style: 'max-width: 220px;'},
              },
              ratType: {
                type: 'addable',
                name: 'RAT Type',
                associatedOption: this.options.modemRatType,
                setEmpty: false,
                ignoreEmptyAdd: true,
                sort: false,
                colAttrs: {style: 'max-width: 230px;'},
              },
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      if (this.valueLine.parameters.radio.ratType === 'any') {
        templateContent.fields.pingLine.fields.configure5G = {
          type: 'addable',
          name: 'Configure 5G',
          associatedOption: [
            ['full', 'including 5G'],
            ['no5GSA', 'excluding 5G SA'],
            ['no5GNSA', 'excluding 5G NSA'],
            ['no5G', 'no 5G at all'],
          ],
          setEmpty: false,
          ignoreEmptyAdd: true,
          sort: false,
          colAttrs: {style: 'max-width: 230px;'},
        };
      }
      templateContent.fields.pingLine.fields.bands = {
        type: 'text',
        inputFieldFormatter: 'explorerCellularBands',
      };
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      let localValue = {...this.value};
      if (index === 'slot' || index === 'type') {
        this.$set(localValue.sim, index, value);
      }else if(index==='cellInfo' && value===0){
        this.$set(localValue, 'cellInfoInterval', "5");
        this.$set(localValue, index, value);
      } else if (index === 'ratType' || index === 'bands' || index === 'configure5G') {
        if (index === 'ratType') {
          if (value === 'any' && localValue.radio.configure5G === undefined) {
            this.$set(localValue.radio, 'configure5G', 'full');
          } else {
            this.$set(localValue.radio, 'configure5G', undefined);
          }
        }
        this.$set(localValue.radio, index, value);
      } else {
        this.$set(localValue, index, value);
      }
      this.$emit('input', localValue);
    },
  },
};
</script>
<style scoped>

</style>